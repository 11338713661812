import React from "react";
import {
  mapping,
  NameCard,
  ButtonSolid,
  NonValidationInput,
  CustomizedCheckBox,
  DropDownListButton,
  userAdminStyle,
} from "@aim-mf/styleguide";
import { ValidationErrorPopup } from "./ValidationErrorPopup";
// this part i think can use AutoComplete from styleguide

class UserProfilePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: this.props.data,
      assignedRoles: this.props.userEditRole,
      validationErrorMsg: "",
      addedRoles: [],
      revokedRoles: [],
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    //console.log(nextProps.userEditRole);
    //console.log(this.props.userEditRole);
    if (JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) {
      this.setState({ userData: nextProps.data });
    }
    if (
      JSON.stringify(nextProps.userEditRole) !==
      JSON.stringify(this.props.userEditRole)
    ) {
      this.setState({ assignedRoles: nextProps.userEditRole });
    }
  }

  render() {
    //console.log(this.props.roleList);
    if (Object.keys(this.state.userData) === 0) {
      return <div />;
    }
    return (
      <div>
        <div
          style={
            this.state.validationErrorMsg !== ""
              ? {
                  ...userAdminStyle.RiskCreationFormWrapStyle,
                  ...userAdminStyle.BlurEffectStyle,
                }
              : { ...userAdminStyle.RiskCreationFormWrapStyle }
          }
        >
          <div style={userAdminStyle.RiskCreationFormStyle}>
            <div style={userAdminStyle.PopupStyle}>
              <div style={userAdminStyle.titleStyle}>Edit User Information</div>
              <div>
                <div
                  style={{
                    display: "inline-flex",
                    width: "100%",
                    placeContent: "center",
                  }}
                >
                  <NameCard
                    initial={this.state.userData.display_name
                      .split(" ")
                      .map((a) => {
                        if (a[0] !== undefined) {
                          return a[0].toUpperCase();
                        }
                      })
                      .join("")}
                    name={this.state.userData.display_name}
                    title={
                      this.state.userData.title
                        ? this.state.userData.title
                        : "Null"
                    }
                  />
                </div>
                <div style={userAdminStyle.fieldPadding} />
                <div id={"edit-field"}>
                  <NonValidationInput
                    title={"Username (for display)"}
                    value={this.state.userData.display_name}
                    width={"100%"}
                    onChange={this.handleDisplayNameChange}
                  />
                  <div style={userAdminStyle.fieldPadding} />
                  <NonValidationInput
                    title={"Password"}
                    value={this.state.userData.password}
                    width={"100%"}
                    onChange={this.handlePasswordChange}
                  />
                  <div style={userAdminStyle.fieldPadding} />
                  <NonValidationInput
                    title={"Email"}
                    value={this.state.userData.email}
                    width={"100%"}
                    onChange={this.handleEmailChange}
                  />
                  <div style={userAdminStyle.fieldPadding} />
                  <div style={userAdminStyle.fieldPadding} />
                  <DropDownListButton
                    title={"Title"}
                    DropDownButtonStyle={
                      userAdminStyle.titleDropDownButtonStyle
                    }
                    data={this.props.titleList.map((a) => a.display_name)}
                    value={this.state.userData.title}
                    CustomOnChange={this.handleTitleChange}
                  />
                  <div style={userAdminStyle.fieldPadding} />
                  <DropDownListButton
                    title={"Access Level"}
                    DropDownButtonStyle={
                      userAdminStyle.titleDropDownButtonStyle
                    }
                    data={Object.values(
                      this.getAccessLevelHigherThanCurrentUser()
                    )}
                    value={
                      this.props.accessLevel[this.state.userData.access_level]
                    }
                    CustomOnChange={this.handleAccessLevelChange}
                  />
                  <div style={userAdminStyle.fieldPadding} />
                  <div>
                    <div style={userAdminStyle.checkboxStyle}>
                      <div style={userAdminStyle.fieldTitleStyle}>Active</div>
                      <CustomizedCheckBox
                        onCheckChange={this.handleActiveChange}
                        value={this.state.userData.is_active}
                      />
                    </div>
                  </div>
                  <div style={userAdminStyle.fieldPadding} />
                  <div>
                    <div style={userAdminStyle.checkboxStyle}>
                      <div style={userAdminStyle.fieldTitleStyle}>
                        Require to change password
                      </div>
                      <CustomizedCheckBox
                        onCheckChange={this.handleRequirePasswordChangeChange}
                        value={this.state.userData.is_pwd_change_required}
                      />
                    </div>
                  </div>
                  <div style={userAdminStyle.fieldPadding} />

                  <DropDownListButton
                    title={"Select a business entity"}
                    data={this.props.OUList.map((ou) => ou.display_name)}
                    value={this.props.OUList.map((ou) => ou.display_name)[0]}
                    DropDownButtonStyle={userAdminStyle.OUDropDownButtonStyle}
                    CustomOnChange={(event) => {
                      this.props.handleRoleRequestOUChange(event.value);
                      this.props.handleUpdateUserQueryUserRole({
                        username: this.props.data.username,
                        ou_id: event.value,
                      });
                    }}
                  />
                  <div style={{ height: "1.25rem" }} />
                  {this.props.roleList.length !== 0 && (
                    <RoleEditComponent
                      roles={this.props.roleList}
                      assignedRoles={this.state.assignedRoles}
                      handleCheckboxChange={(role) => {
                        this.handleRoleChange(role);
                      }}
                    />
                  )}
                </div>

                <div style={userAdminStyle.buttonWrapper}>
                  <ButtonSolid
                    name={"Cancel"}
                    height={userAdminStyle.buttonHeight}
                    width={userAdminStyle.cancelButtonwidth}
                    size={"Small"}
                    clickEvent={this.props.cancelFunction}
                  />
                  <ButtonSolid
                    name={"Submit"}
                    height={userAdminStyle.buttonHeight}
                    width={userAdminStyle.roleUpdateButtonWidth}
                    size={"Small"}
                    clickEvent={() => {
                      this.userUpdateSubmit();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.validationErrorMsg !== "" && (
          <ValidationErrorPopup
            validationErrorMsg={this.state.validationErrorMsg}
            close={() => {
              this.setState({ validationErrorMsg: "" });
            }}
          />
        )}
      </div>
    );
  }
  userUpdateSubmit = () => {
    let userDatePayload = JSON.parse(JSON.stringify(this.state.userData));
    // check email and password validation
    let passwordValidation = true;
    if (
      userDatePayload.password !== "" &&
      userDatePayload.password !== undefined
    ) {
      passwordValidation = this.validateUserPassword(userDatePayload.password);
    }
    let emailValidation = this.validateUserEmail(userDatePayload.email);
    if (!emailValidation) {
      this.setState({
        validationErrorMsg: "the new email you have entered is not valid",
      });
    } else if (!passwordValidation) {
      this.setState({
        validationErrorMsg: "the new password you have entered is not valid",
      });
    } else {
      if (this.state.userData.email === this.props.data.email) {
        delete userDatePayload.email;
      }
      //console.log(this.state.revokedRoles)
      this.props.handleUpdateUser(
        userDatePayload,
        this.state.addedRoles,
        this.state.revokedRoles
      );
      this.setState({ addedRole: [], revokedRoles: [] });
      this.props.cancelFunction();
    }
  };

  validateUserPassword = (password) => {
    let myRe = new RegExp(
      "^(^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$)?(^(?=.*\\d)(?=.*[a-z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*\\d)(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?$",
      "g"
    );
    let myArray = myRe.exec(password);
    if (myArray !== null) {
      return true;
    }
  };

  validateUserEmail = (email) => {
    let myRe = new RegExp(
      '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
      "g"
    );
    let myArray = myRe.exec(email);
    if (myArray !== null) {
      return true;
    }
  };
  getAccessLevelHigherThanCurrentUser = () => {
    let filtered = Object.keys(this.props.accessLevel)
      .filter((lvl) => {
        return lvl > this.props.currentUser.access_level;
      })
      .reduce((obj, key) => {
        obj[key] = this.props.accessLevel[key];
        return obj;
      }, {});
    return filtered;
  };

  handleRoleChange = (role) => {
    //console.log(role);
    let assignedRole = this.state.assignedRoles;
    let addedRole = this.state.addedRoles;
    let revokedRoles = this.state.revokedRoles;
    // remove existing role
    if (
      this.state.assignedRoles
        .map((role) => role.role_id)
        .includes(role.role_id)
    ) {
      // if the role is added just now
      if (
        this.state.addedRoles.map((role) => role.role_id).includes(role.role_id)
      ) {
        addedRole = this.state.addedRoles;
        addedRole = addedRole.filter((addedRole) => {
          return addedRole.role_id !== role.role_id;
        });
        this.setState({ addedRole: addedRole });
      } else {
        // the role is not added just now, need to revoke
        revokedRoles = this.state.revokedRoles;
        revokedRoles.push(role);
      }
      assignedRole = assignedRole.filter((assignedRole) => {
        return assignedRole.role_id !== role.role_id;
      });
    } else {
      if (
        this.state.revokedRoles
          .map((role) => {
            role.role_id;
          })
          .includes(role.role_id)
      ) {
        revokedRoles = this.state.revokedRoles;
        revokedRoles = revokedRoles.filter((revokedRole) => {
          return revokedRole.role_id !== role.role_id;
        });
      }
      addedRole.push(role);
      assignedRole.push(role);
    }
    this.setState({
      assignedRoles: assignedRole,
      addedRole: addedRole,
      revokedRoles: revokedRoles,
    });
  };

  handlePasswordChange = (event) => {
    let userData = this.state.userData;
    userData.password = event.value;
    this.setState({ userData: userData });
  };
  handleEmailChange = (event) => {
    let userData = JSON.parse(JSON.stringify(this.state.userData));
    userData.email = event.value;
    this.setState({ userData: userData });
  };
  handleDisplayNameChange = (event) => {
    let userData = this.state.userData;
    userData.display_name = event.value;
    this.setState({ userData: userData });
  };
  handleTitleChange = (event) => {
    let titleName = event.value;
    let titleID = this.props.titleList.filter((title) => {
      return title.display_name === titleName;
    })[0].title_id;

    let userData = this.state.userData;
    userData.title = titleName;
    userData.title_id = titleID;
    this.setState({ userData: userData });
  };

  handleAccessLevelChange = (event) => {
    let userData = this.state.userData;
    userData.access_level = Object.keys(this.props.accessLevel).find(
      (key) => this.props.accessLevel[key] === event.value
    );
    this.setState({ userData: userData });
  };

  handleActiveChange = () => {
    let userData = this.state.userData;
    userData.is_active = !userData.is_active;
    this.setState({ userData: userData });
  };
  handleRequirePasswordChangeChange = () => {
    let userData = this.state.userData;
    userData.is_pwd_change_required = !userData.is_pwd_change_required;
    this.setState({ userData: userData });
  };
}
const RoleEditComponent = (props) => {
  return (
    <div style={{ color: "white" }}>
      {props.roles.map((role) => {
        //console.log(props.assignedRoles);
        //console.log(role.display_name);
        return (
          <CustomizedCheckBox
            name={role.display_name}
            label={role.display_name}
            value={props.assignedRoles
              .map((a) => a.role_id)
              .includes(role.role_id)}
            onCheckChange={() => {
              props.handleCheckboxChange(role);
            }}
            checkBoxWrapperStyle={userAdminStyle.checkBoxWrapperStyle}
            key={role.role_id}
          />
        );
      })}
    </div>
  );
};

export { UserProfilePopup };
