import { userAdminStyle } from "@aim-mf/styleguide";
import React from "react";

class HeaderRender extends React.Component {
  render() {
    return (
      <span style={userAdminStyle.headerCellStyle}>{this.props.title}</span>
    );
  }
}
export { HeaderRender };
