import React from "react";
import {
  mapping,
  NonValidationInput,
  ButtonSolid,
  DropDownListButton,
  CustomizedCheckBox,
  userAdminStyle,
} from "@aim-mf/styleguide";

// this part i think can use AutoComplete from styleguide

class RoleCreationPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ou_id: this.props.OUData.map((ou) => ou.ou_id)[0],
      frontend: [],
      display_name: "",
      permission: {},
    };
  }

  componentDidMount() {
    this.props.handlePermissionListQuery({ ou_id: this.state.ou_id });
    let permission = {};
    if (this.props.permissionList.entity) {
      this.props.permissionList.entity.forEach((entity) => {
        permission[entity.entity_id] = {
          create: false,
          view: false,
          edit: false,
          delete: false,
          approve: false,
        };
      });
      //console.log(permission);
      this.setState({ permission: permission });
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    //console.log(nextProps.permissionList);
    if (
      JSON.stringify(nextProps.permissionList.entity) !==
      JSON.stringify(this.props.permissionList.entity)
    ) {
      let permission = {};
      nextProps.permissionList.entity.forEach((entity) => {
        permission[entity.entity_id] = {
          create: false,
          view: false,
          edit: false,
          delete: false,
          approve: false,
        };
      });
      //console.log(permission);
      this.setState({ permission: permission });
    }
  }

  render() {
    //console.log(this.props.permissionList)
    if (this.props.permissionList.length === 0) {
      return <div />;
    }
    return (
      <div style={userAdminStyle.RiskCreationFormWrapStyle}>
        <div style={userAdminStyle.RiskCreationFormStyle}>
          <div style={userAdminStyle.formBgStyle}>
            <div style={mapping["heading/h5/lightleft"]}>Create a new role</div>
            <div style={{ height: "50px" }} />
            <DropDownListButton
              title={"Business Entity"}
              data={this.props.OUData.map((ou) => ou.display_name)}
              value={this.props.OUData.map((ou) => ou.display_name)[0]}
              DropDownButtonStyle={userAdminStyle.roleOUDropDownButtonStyle}
              CustomOnChange={this.handleOUChange}
            />

            <div style={userAdminStyle.fieldPadding} />
            <div style={mapping["forms/label/1-default"]}>Role name</div>
            <NonValidationInput
              fieldstyle={userAdminStyle.roleInputFieldStyle}
              width={"100%"}
              minRows={3}
              onChange={(event) => {
                // eslint-disable-next-line no-console
                this.setState({ display_name: event.value });
              }}
            />

            <div style={userAdminStyle.fieldPadding} />
            <div style={mapping["forms/label/1-default"]}>Role Rights</div>

            {this.props.permissionList.entity.map((entity) => {
              return (
                <RightsSelection
                  permission={this.state.permission[entity.entity_id]}
                  data={entity}
                  handlePermissionCreateChange={
                    this.handlePermissionCreateChange
                  }
                  handlePermissionViewChange={this.handlePermissionViewChange}
                  handlePermissionDeleteChange={
                    this.handlePermissionDeleteChange
                  }
                  handlePermissionEditChange={this.handlePermissionEditChange}
                  handlePermissionApproveChange={
                    this.handlePermissionApproveChange
                  }
                />
              );
            })}
            <div style={{ height: "2.5rem" }} />
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ButtonSolid
                name={"cancel"}
                color={"Secondary"}
                width={userAdminStyle.cancelButtonWidth}
                height={userAdminStyle.buttonHeight}
                clickEvent={this.props.cancelFunction}
              />
              <ButtonSolid
                name={"Create Role"}
                color={"Primary"}
                width={userAdminStyle.roleUpdateButtonWidth}
                height={userAdminStyle.buttonHeight}
                clickEvent={this.handleRoleCreation}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleRoleCreation = () => {
    let permission = {};
    for (let key in this.state.permission) {
      let value = this.state.permission[key];
      let permissiondecimal =
        (value.create ? 1 : 0) +
        ((value.view ? 1 : 0) << 1) +
        ((value.edit ? 1 : 0) << 2) +
        ((value.delete ? 1 : 0) << 3) +
        ((value.approve ? 1 : 0) << 4) +
        ((value.create ? 1 : 0) << 5) +
        ((value.view ? 1 : 0) << 6) +
        ((value.edit ? 1 : 0) << 7) +
        ((value.delete ? 1 : 0) << 8) +
        ((value.approve ? 1 : 0) << 9);
      permission[key.toString()] = permissiondecimal;
    }
    let roleCreationPayload = {
      ou_id: this.state.ou_id,
      frontend: ["dashboard", "my_task", "risk_management"],
      display_name: this.state.display_name,
      permission: permission,
    };
    //console.log(roleCreationPayload);
    this.props.submitFunction(roleCreationPayload);
    this.props.cancelFunction();
  };

  handleOUChange = (event) => {
    let ou_name = event.value;
    let ou_id = this.props.OUData.filter((OU) => {
      return OU.display_name === ou_name;
    })[0].ou_id;
    this.setState({ ou_id: ou_id });
  };
  handlePermissionCreateChange = (entityID) => {
    let permission = this.state.permission;
    permission[entityID].create = !permission[entityID].create;
    this.setState({ permission: permission });
  };
  handlePermissionViewChange = (entityID) => {
    let permission = this.state.permission;
    permission[entityID].view = !permission[entityID].view;
    this.setState({ permission: permission });
  };
  handlePermissionEditChange = (entityID) => {
    let permission = this.state.permission;
    permission[entityID].edit = !permission[entityID].edit;
    this.setState({ permission: permission });
  };
  handlePermissionDeleteChange = (entityID) => {
    let permission = this.state.permission;
    permission[entityID].delete = !permission[entityID].delete;
    this.setState({ permission: permission });
  };
  handlePermissionApproveChange = (entityID) => {
    let permission = this.state.permission;
    permission[entityID].approve = !permission[entityID].approve;
    this.setState({ permission: permission });
  };
}

const RightsSelection = (props) => {
  return (
    <div>
      <div style={userAdminStyle.fieldTitleStyle}>
        {props.data.display_name} ({props.data.service})
      </div>
      <div style={userAdminStyle.fieldPadding} />
      <div>
        <div style={userAdminStyle.checkboxStyle}>
          <div style={userAdminStyle.fieldTitleStyle}>Create</div>
          <CustomizedCheckBox
            onCheckChange={() => {
              props.handlePermissionCreateChange(props.data.entity_id);
            }}
            value={props.permission ? props.permission.create : false}
          />
        </div>
      </div>
      <div style={userAdminStyle.halfFieldPadding} />
      <div>
        <div style={userAdminStyle.checkboxStyle}>
          <div style={userAdminStyle.fieldTitleStyle}>View</div>
          <CustomizedCheckBox
            onCheckChange={() => {
              props.handlePermissionViewChange(props.data.entity_id);
            }}
            value={props.permission ? props.permission.view : false}
          />
        </div>
      </div>
      <div style={userAdminStyle.halfFieldPadding} />
      <div>
        <div style={userAdminStyle.checkboxStyle}>
          <div style={userAdminStyle.fieldTitleStyle}>Edit</div>
          <CustomizedCheckBox
            onCheckChange={() => {
              props.handlePermissionEditChange(props.data.entity_id);
            }}
            value={props.permission ? props.permission.edit : false}
          />
        </div>
      </div>
      <div style={userAdminStyle.halfFieldPadding} />
      <div>
        <div style={userAdminStyle.checkboxStyle}>
          <div style={userAdminStyle.fieldTitleStyle}>Delete</div>
          <CustomizedCheckBox
            onCheckChange={() => {
              props.handlePermissionDeleteChange(props.data.entity_id);
            }}
            value={props.permission ? props.permission.delete : false}
          />
        </div>
      </div>
      <div style={userAdminStyle.halfFieldPadding} />
      <div>
        <div style={userAdminStyle.checkboxStyle}>
          <div style={userAdminStyle.fieldTitleStyle}>Approve</div>
          <CustomizedCheckBox
            onCheckChange={() => {
              props.handlePermissionApproveChange(props.data.entity_id);
            }}
            value={props.permission ? props.permission.approve : false}
          />
        </div>
      </div>
    </div>
  );
};

export { RoleCreationPopUp };
