import React from "react";
import {
  Tab,
  CustomizedCheckBox,
  ButtonSolid,
  DropDownListButton,
  userAdminStyle,
} from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

class UserRoleAssign extends React.Component {
  state = {
    roles: this.props.roleList,
    assignedRoles: [],
    tabs: "Add Rule",
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      JSON.stringify(nextProps.roleList) !== JSON.stringify(this.props.roleList)
    ) {
      this.setState({ roles: nextProps.roleList });
    }
  }

  handleCheckboxChange = (roleClicked) => {
    //console.log(role);
    let assignedRoles = this.state.assignedRoles;
    if (assignedRoles.map((a) => a.role_id).includes(roleClicked.role_id)) {
      assignedRoles = assignedRoles.filter((role) => {
        return role.role_id !== roleClicked.role_id;
      });
    } else {
      assignedRoles.push(roleClicked);
    }
    //console.log(assignedRoles);
    this.setState({ assignedRoles: assignedRoles });
  };

  handleTabChange = () => {
    // this.setState({ tabs: newName });
    // console.log(name);
  };
  render() {
    //console.log(this.props.roleList);
    return (
      <div style={{ width: "58.75rem", margin: "auto" }}>
        <div style={userAdminStyle.userRoleAssignStyle}>
          <div style={userAdminStyle.tabStyle}>
            <Tab
              OnTabChange={this.handleTabChange}
              tabWidth={"8.6875rem"}
              height={"3rem"}
              labels={["Add Role"]}
              activeBackgroundColor={"#182237"}
            />
          </div>
          <DropDownListButton
            title={"Select a business entity"}
            data={this.props.OUList.map((ou) => ou.display_name)}
            value={this.props.OUList.map((ou) => ou.display_name)[0]}
            DropDownButtonStyle={userAdminStyle.OUDropDownButtonStyle}
            CustomOnChange={(event) => {
              this.props.handleRoleRequestOUChange(event.value);
              this.props.handleUserRoleRequestOUChange(event.value);
            }}
          />
          <div style={{ height: "1.25rem" }} />
          <RoleAssignComponent
            roles={this.state.roles}
            assignedRoles={this.state.assignedRoles}
            handleCheckboxChange={this.handleCheckboxChange}
          />
        </div>

        <div style={userAdminStyle.roleAssignButtonPosition}>
          <ButtonSolid
            name={"Cancel"}
            color={"Secondary"}
            width={userAdminStyle.cancelButtonWidth}
            height={userAdminStyle.buttonHeight}
            clickEvent={() => {
              navigateToUrl("/admin");
            }}
          />

          <div style={userAdminStyle.rightButtonPosition}>
            <ButtonSolid
              name={"Add User"}
              width={"5rem"}
              width={userAdminStyle.cancelButtonWidth}
              height={userAdminStyle.buttonHeight}
              clickEvent={() => {
                this.props.handleAddUser(this.state.assignedRoles);
              }}
            />
            <div style={{ width: "1rem" }} />
            <ButtonSolid
              name={"Back"}
              width={userAdminStyle.cancelButtonWidth}
              height={userAdminStyle.buttonHeight}
              clickEvent={this.props.handlePrevious}
            />
          </div>
        </div>
      </div>
    );
  }
}

const RoleAssignComponent = (props) => {
  return (
    <div style={{ color: "white" }}>
      {props.roles.map((role) => {
        return (
          <CustomizedCheckBox
            name={role.display_name}
            label={role.display_name}
            value={props.assignedRoles
              .map((a) => a.display_name)
              .includes(role.display_name)}
            onCheckChange={() => {
              props.handleCheckboxChange(role);
            }}
            checkBoxWrapperStyle={userAdminStyle.checkBoxWrapperStyle}
            key={role}
          />
        );
      })}
    </div>
  );
};

export { UserRoleAssign, RoleAssignComponent };
