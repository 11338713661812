import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import {
  IconButtonSolid,
  SearchBar,
  mapping,
  IconSVG,
  userAdminStyle,
} from "@aim-mf/styleguide";
import { HeaderRender } from "../../tableHeaderRender";

class TitleListTable extends React.Component {
  constructor(props) {
    super(props);
    let dataWithSelection = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    this.state = {
      sort: [{ field: "formatDateTime", dir: "asc", data: this.props.data }],
      result: dataWithSelection,
      filterData: dataWithSelection,
      searchData: dataWithSelection,
      dataState: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      let dataWithSelection = nextProps.data.map((dataItem) =>
        Object.assign({ selected: false }, dataItem)
      );
      this.setState({
        sort: [{ field: "createdDatetime", dir: "asc", data: nextProps.data }],
        result: dataWithSelection,
        filterData: dataWithSelection,
        searchData: dataWithSelection,
        dataState: "",
      });
    }
  }

  selectionChange = (event) => {
    const result = this.state.result.map((item) => {
      if (item.id === event.dataItem.id) {
        item.selected = !event.dataItem.selected;
      }
      return item;
    });
    this.setState({ result });
  };
  rowClick = (event) => {
    let last = this.lastSelectedIndex;
    const result = [...this.state.result];
    const current = result.findIndex((dataItem) => dataItem === event.dataItem);
    result.forEach((item) => (item.selected = false));
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      result[i].selected = select;
    }
    this.setState({ result });
  };

  headerSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const result = this.state.result.map((item) => {
      item.selected = checked;
      return item;
    });
    this.setState({ result });
  };

  // search related
  onSearchCallBack = (e) => {
    let searchContent = e.target.value;

    let resultData = this.state.filterData.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    let searchData = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );

    if (!searchContent) {
      this.setState({ result: resultData, searchData: searchData });
      return;
    }
    // find the risks that suits the search keywords (risk name and risk owner)
    let titleOnSearch = this.state.filterData.filter((titleItem) => {
      let matchTitleName = false;
      if (titleItem.display_name) {
        matchTitleName = titleItem.display_name
          .toLowerCase()
          .includes(searchContent.toLowerCase());
      }
      return matchTitleName;
    });
    this.setState({ searchData: titleOnSearch, result: titleOnSearch });
  };

  // filter related
  createDataState = (dataState) => {
    let resultData = this.state.searchData.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    let filterData = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    return {
      result: process(resultData, dataState).data,
      filterData: process(filterData, dataState).data,
      dataState: dataState,
    };
  };

  dataStateChange = (event) => {
    var datastate = event.data;
    datastate.take = 100;
    this.setState(this.createDataState(datastate));
  };

  //archive function
  archiveRisk = () => {
    // get all risk with selected
    let selectedRisk = this.state.result.filter((risk) => {
      return risk.selected;
    });
    let archiveRiskID = selectedRisk.map((a) => a.id);
    this.props.archive(archiveRiskID);
  };

  render() {
    let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    // reformat result cater for the display requirement
    let displayResult = this.state.result;

    return (
      <div>
        <div style={userAdminStyle.tableTitlePadding}>
          <div style={userAdminStyle.titleStyle}>Title Management</div>
          <div className="risk-card-subtitle">
            This manages and register the systems' titles
          </div>
        </div>
        <div
          id="table-tool-bar"
          style={{
            ...userAdminStyle.toolBarWrapper,
            marginRight: "1rem",
            justifyContent: "flex-end",
          }}
          className="mb-1"
        >
          <div className="mx-1">
            <SearchBar
              searchCallBack={this.onSearchCallBack}
              hint={"Search by risk name or risk owner..."}
            />
          </div>
          <div className="mx-1">
            <IconButtonSolid
              height={"2rem"}
              width={"fit-content"}
              color={"Danger"}
              name={"Archive"}
              iconPos={"Left"}
              icon={<IconSVG name={"archive"} size={0.8} color={"#ffffff"} />}
              clickEvent={this.archiveRisk}
            />
          </div>
          <div className="mx-1">
            <IconButtonSolid
              height={"2rem"}
              width={"fit-content"}
              iconPos={"Left"}
              icon={<IconSVG name={"newRisk"} size={0.8} color={"#ffffff"} />}
              name={"Add Title"}
              clickEvent={this.props.handleCreateTitle}
            />
          </div>
        </div>

        <Grid
          style={userAdminStyle.gridStyle}
          {...this.state.dataState}
          data={displayResult}
          onDataStateChange={this.dataStateChange}
          selectedField="selected"
          onSelectionChange={this.selectionChange}
          onHeaderSelectionChange={this.headerSelectionChange}
          onRowClick={this.rowClick}
          rowRender={this.rowRender}
        >
          <Column
            field="selected"
            width={(50 / 16) * rem + "px"}
            headerSelectionValue={
              this.state.result.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />
          <Column field="display_name" title="Name" headerCell={HeaderRender} />
          <Column
            title="Edit"
            width={(60 / 16) * rem + "px"}
            cell={this.editCell}
            headerCell={HeaderRender}
          />
          <Column
            title="Delete"
            width={(70 / 16) * rem + "px"}
            cell={this.deleteCell}
            headerCell={HeaderRender}
          />
        </Grid>
      </div>
    );
  }

  editCell = (props) => {
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <td
        onClick={() => {
          this.props.handleEditTitle(props.dataItem);
        }}
      >
        <div style={userAdminStyle.tableIconStyle}>
          <IconSVG
            name={"edit"}
            size={1}
            color={mapping["Color_Basic_Primary"]}
          />
        </div>
      </td>
    );
  };

  deleteCell = (props) => {
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <td
        onClick={() => {
          this.props.handleDeleteTitle({ title_id: props.dataItem.title_id });
        }}
      >
        <div style={userAdminStyle.tableIconStyle}>
          <IconSVG
            name={"delete"}
            size={1}
            color={mapping["Color_Basic_Primary"]}
          />
        </div>
      </td>
    );
  };

  rowRender(trElement, props) {
    const parentRow = { backgroundColor: "#00000066" };
    const subRiskRow = { backgroundColor: "#00000000" };
    const trProps = {
      style: props.dataItem.isParentRisk ? parentRow : subRiskRow,
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  }
}

export { TitleListTable };
