import React from "react";
import { ButtonSolid, userAdminStyle } from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

// can check how i did for blur effect in risk management, on file "risk Management"
class CreationCompletePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        name={"CreationComplete"}
        style={userAdminStyle.creationCompletePageStyle}
      >
        <div style={userAdminStyle.creationCompleteStyle}>
          <div style={userAdminStyle.accountCreatedStyle}>
            Account Successfully Created
          </div>
          <div style={userAdminStyle.lineStyle} />
          <div style={userAdminStyle.emailSentStyle}>
            An email notification was sent to {this.props.email}
          </div>
          <div style={userAdminStyle.buttonPosition}>
            <ButtonSolid
              name={"Back to Dashboard"}
              height={userAdminStyle.buttonHeight}
              width={"9.375rem"}
              color={"Primary"}
              clickEvent={() => {
                navigateToUrl(this.props.aimBaseUrl + "admin");
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
export { CreationCompletePopup };
