import React from "react";
import { mapping, userAdminStyle } from "@aim-mf/styleguide";
import { UserRoleAssign } from "./userRoleAssign";
import { UserAccountDetail } from "./userAccountDetail";
import { CreationCompletePopup } from "./creationCompletePopup";
import { UserProfilePopup } from "../userProfilePopup";

// few points to note:
// 1. rmb, every style uses variables, dun just push inside the html tag, so later it is easier for me to change.
// 2. react style is different from html style, basicly, the react style is CamelCase,   e.g.==>  html: background-color,   react: backgroundColor,
// 3. if you wanna some good example, you could update your risk-edit git repo, and find file "riskRating.js" under src/src/page/component
// 4. all dummy data should come from this file, and aggregate (if necessary) to this file. (i have created the variable testUserList for you)

class CreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      created: false,
      userID: -1,
      newUserData: {},
    };
  }

  render() {
    return (
      <div name={"Create Account"}>
        <div
          style={
            this.state.created
              ? {
                  ...userAdminStyle.userCreateTitleStyle,
                  ...userAdminStyle.BlurEffectStyle,
                }
              : userAdminStyle.userCreateTitleStyle
          }
        >
          Create Account
        </div>
        <div
          style={
            this.state.created
              ? {
                  ...userAdminStyle.userCreateTitleStyle,
                  ...userAdminStyle.BlurEffectStyle,
                }
              : userAdminStyle.userCreateTitleStyle
          }
        >
          <div
            style={
              this.state.step >= 1
                ? {
                    ...userAdminStyle.stepStyle,
                    ...userAdminStyle.stepHighlightedStyle,
                  }
                : userAdminStyle.stepStyle
            }
          >
            1
          </div>
          <div style={userAdminStyle.lineStyleHighlighted} />
          <div
            style={
              this.state.step >= 2
                ? userAdminStyle.stepHighlightedStyle
                : userAdminStyle.stepStyle
            }
          >
            2
          </div>
        </div>
        <div
          style={
            this.state.created
              ? {
                  ...userAdminStyle.userCreateTitleStyle,
                  ...userAdminStyle.BlurEffectStyle,
                }
              : userAdminStyle.userCreateTitleStyle
          }
        >
          <div
            style={
              this.state.step >= 1
                ? userAdminStyle.searchUserHighlighted
                : userAdminStyle.searchUser
            }
          >
            User Account Detail
          </div>
          <div style={userAdminStyle.lineBetweenTitle} />
          <div
            style={
              this.state.step >= 2
                ? userAdminStyle.searchUserHighlighted
                : userAdminStyle.searchUser
            }
          >
            Add User Rights
          </div>
        </div>
        <div>
          <div
            style={
              this.state.created
                ? userAdminStyle.BlurEffectStyle
                : { dummy: "dummy" }
            }
          >
            {this.state.step === 1 && (
              <UserAccountDetail
                currentUser={this.props.currentUser}
                titleList={this.props.titleList}
                handleNext={this.handleNext}
                handlePrevious={this.handlePrevious}
                accessLevel={this.props.accessLevel}
                saveNewUserInfo={(newUserData) => {
                  this.setState({ newUserData: newUserData });
                }}
              />
            )}
            {this.state.step === 2 && (
              <UserRoleAssign
                handleAddUser={this.handleAddUser}
                handlePrevious={this.handlePrevious}
                OUList={this.props.OUList}
                roleList={this.props.roleList}
                userRole={this.props.userRole}
                handleUserRoleRequestOUChange={
                  this.props.handleUserRoleRequestOUChange
                }
                handleRoleRequestOUChange={this.props.handleRoleRequestOUChange}
              />
            )}
          </div>
          <div>
            {this.state.created === true && this.state.step === 2 && (
              <CreationCompletePopup
                email={this.state.newUserData.email}
                aimBaseUrl={this.props.aimBaseUrl}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
  handleNext = () => {
    this.setState((prevState) => {
      return { step: prevState.step + 1 };
    });
  };
  handlePrevious = () => {
    this.setState((prevState) => {
      return { step: prevState.step - 1 };
    });
  };
  handleAddUser = (roles) => {
    //console.log(roles);
    this.setState(() => {
      return { created: true };
    });
    this.props.handleCreateUser(this.state.newUserData, roles);
  };
}

export { CreateUser };
