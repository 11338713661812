import React from "react";
import { RoleListTable } from "./roleListTable";
import { RoleCreationPopUp } from "./roleCreationPopUp";
import { RoleEditPopUp } from "./roleeditPopup";
import { userAdminStyle } from "@aim-mf/styleguide";

// this part i think can use AutoComplete from styleguide

class ManageRolePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ou_name: "",
      createRole: { display: false },
      editRole: { display: false },
    };
  }

  render() {
    return (
      <div>
        <div
          style={
            this.state.createRole.display || this.state.editRole.display
              ? userAdminStyle.BlurEffectStyle
              : { dummy: "dummy" }
          }
        >
          <div style={userAdminStyle.userTableWrapper}>
            <RoleListTable
              OUData={this.props.OUList}
              data={this.props.roleData}
              handleCreateRole={this.handleCreateRole}
              handleDeleteRole={this.props.handleDeleteRole}
              handleEditRole={this.handleEditRole}
              handleRoleRequestOUChange={this.props.handleRoleRequestOUChange}
              //handleUpdateUser={this.props.handleUpdateUser}
              //addNew={this.createRiskHandler}
              //archive={this.props.handleArchiveRisk}
              //rights={this.props.rights}
            />
          </div>
          <div style={{ height: "2.5rem" }} />
        </div>
        {this.state.createRole.display && (
          <RoleCreationPopUp
            OUData={this.props.OUList}
            permissionList={this.props.permissionList}
            cancelFunction={this.handleCreateRoleCancel}
            submitFunction={this.props.handleCreateRole}
            handlePermissionListQuery={this.props.handlePermissionListQuery}
          />
        )}
        {this.state.editRole.display && (
          <RoleEditPopUp
            OUData={this.props.OUList}
            permissionList={this.props.permissionList}
            handlePermissionListQuery={this.props.handlePermissionListQuery}
            data={this.state.editRole.data}
            roleQuery={this.props.roleQuery}
            cancelFunction={this.handleEditRoleCancel}
            submitFunction={this.props.handleEditRole}
          />
        )}
      </div>
    );
  }
  handleCreateRole = () => {
    this.setState({ createRole: { display: true } });
  };
  handleCreateRoleCancel = () => {
    this.setState({ createRole: { display: false } });
  };
  handleEditRole = (data) => {
    this.props.handleRoleQuery(data);
    this.setState({ editRole: { display: true, data: data } });
  };
  handleEditRoleCancel = () => {
    this.setState({ editRole: { display: false } });
  };
}
export { ManageRolePage };
