import React from "react";
import { userAdminStyle, ButtonSolid } from "@aim-mf/styleguide";

// can check how i did for blur effect in risk management, on file "risk Management"
class ValidationErrorPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div name={"CreationComplete"} style={userAdminStyle.creationPageStyle}>
        <div style={userAdminStyle.creationCompleteStyle}>
          <div style={userAdminStyle.accountCreatedStyle}>
            Account Information Invalid
          </div>
          <div style={userAdminStyle.lineStyle} />
          <div style={userAdminStyle.emailSentStyle}>
            {this.props.validationErrorMsg}
          </div>
          <div style={userAdminStyle.buttonPosition}>
            <ButtonSolid
              name={"Close"}
              width={"9.375rem"}
              color={"Primary"}
              clickEvent={() => {
                this.props.close();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export { ValidationErrorPopup };
