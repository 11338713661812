import React from "react";
import { TitleListTable } from "./title/titleListTable";
import { TitleCreationPopUp } from "./title/titleCreationPopUp";
import { TitleEditPopUp } from "./title/titleEditPopUp";
import { userAdminStyle } from "@aim-mf/styleguide";

// this part i think can use AutoComplete from styleguide

class ManageTitlePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ou_name: "",
      createOU: { display: false },
      createRole: { display: false },
      createTitle: { display: false },
      editOU: { display: false },
      editRole: { display: false },
      editTitle: { display: false },
    };
  }

  render() {
    return (
      <div>
        <div
          style={
            this.state.createTitle.display || this.state.editTitle.display
              ? userAdminStyle.BlurEffectStyle
              : { dummy: "dummy" }
          }
        >
          <div style={{ height: "1.25rem" }} />
          <div style={userAdminStyle.userTableWrapper}>
            <TitleListTable
              data={this.props.titleData}
              handleCreateTitle={this.handleCreateTitle}
              handleDeleteTitle={this.props.handleDeleteTitle}
              handleEditTitle={this.handleEditTitle}
            />
          </div>
          <div style={{ height: "2.5rem" }} />
        </div>
        {this.state.createTitle.display && (
          <TitleCreationPopUp
            cancelFunction={this.handleCreateTitleCancel}
            submitFunction={this.props.handleCreateTitle}
          />
        )}
        {this.state.editTitle.display && (
          <TitleEditPopUp
            data={this.state.editTitle.data}
            cancelFunction={this.handleEditTitleCancel}
            submitFunction={this.props.handleEditTitle}
          />
        )}
      </div>
    );
  }

  handleCreateTitle = () => {
    this.setState({ createTitle: { display: true } });
  };
  handleCreateTitleCancel = () => {
    this.setState({ createTitle: { display: false } });
  };
  handleEditTitle = (data) => {
    this.setState({ editTitle: { display: true, data: data } });
  };
  handleEditTitleCancel = () => {
    this.setState({ editTitle: { display: false } });
  };
}

export { ManageTitlePage };
