import React from "react";
import { OperatingUnitListTable } from "./OU/operatingUnitListTable";
import { OperatingUnitCreationPopUp } from "./OU/operatingUnitCreationPopUp";
import { OperatingUnitEditPopUp } from "./OU/operatingUnitEditPopUp";
import { userAdminStyle } from "@aim-mf/styleguide";

// this part i think can use AutoComplete from styleguide

class ManageOUPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ou_name: "",
      createOU: { display: false },
      editOU: { display: false },
    };
  }

  render() {
    return (
      <div>
        <div
          style={
            this.state.createOU.display || this.state.editOU.display
              ? userAdminStyle.BlurEffectStyle
              : { dummy: "dummy" }
          }
        >
          <div style={userAdminStyle.userTableWrapper}>
            <OperatingUnitListTable
              data={this.props.OUData}
              handleCreateOU={this.handleCreateOU}
              handleEditOU={this.handleEditOU}
              handleDeleteOU={this.props.handleDeleteOU}
            />
          </div>
          <div style={{ height: "2.5rem" }} />
        </div>
        {this.state.createOU.display && (
          <OperatingUnitCreationPopUp
            cancelFunction={this.handleCreateOUCancel}
            submitFunction={this.props.handleCreateOU}
          />
        )}
        {this.state.editOU.display && (
          <OperatingUnitEditPopUp
            data={this.state.editOU.data}
            cancelFunction={this.handleEditOUCancel}
            submitFunction={this.props.handleEditOU}
          />
        )}
      </div>
    );
  }

  handleCreateOU = () => {
    this.setState({ createOU: { display: true } });
  };
  handleCreateOUCancel = () => {
    this.setState({ createOU: { display: false } });
  };
  handleEditOU = (data) => {
    this.setState({ editOU: { display: true, data: data } });
  };
  handleEditOUCancel = () => {
    this.setState({ editOU: { display: false } });
  };
}

export { ManageOUPage };
