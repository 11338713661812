import React from "react";
import {
  GridColumnMenuCheckboxFilter,
  Grid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { orderBy, process } from "@progress/kendo-data-query";
import {
  FormatDate,
  IconSVG,
  mapping,
  CustomDatePicker,
  JSDateTimeToDBDateTime,
  SearchBar,
  userAdminStyle,
} from "@aim-mf/styleguide";
import { HeaderRender } from "../tableHeaderRender";

class LogsTable extends React.Component {
  constructor(props) {
    super(props);
    let dataWithSelection = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    let today = new Date(new Date().setHours(23, 59, 59, 999));
    let lastMonth = new Date(new Date().setHours(0, 0, 0, 0));
    lastMonth = lastMonth.setMonth(today.getMonth() - 1);
    this.state = {
      sort: [{ field: "log_time", dir: "asc", data: this.props.data }],
      result: dataWithSelection,
      filterData: dataWithSelection,
      searchData: dataWithSelection,
      dataState: "",
      editUser: { display: false },
      endingDate: today,
      startingDate: lastMonth,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      let dataWithSelection = nextProps.data.map((dataItem) =>
        Object.assign({ selected: false }, dataItem)
      );
      this.setState({
        sort: [{ field: "log_time", dir: "asc", data: nextProps.data }],
        result: dataWithSelection,
        filterData: dataWithSelection,
        searchData: dataWithSelection,
        dataState: "",
      });
    }
  }

  render() {
    let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    // reformat result cater for the display requirement
    let displayResult = this.state.result;
    displayResult = displayResult.map((a) => {
      let log_time = FormatDate(a.timestamp, "MM/DD/YYYY HH:MM:SS");
      let ou_name;
      if (a.ou_id !== null) {
        ou_name = this.props.OUList.filter((ou) => {
          return ou.ou_id === a.ou_id;
        })[0].display_name;
      } else {
        ou_name = "";
      }
      return { ...a, log_time: log_time, ou_name: ou_name };
    });

    return (
      <div style={userAdminStyle.userTableWrapper}>
        <div style={userAdminStyle.tableTitlePadding}>
          <div style={userAdminStyle.titleStyle}>Audit Logs View</div>
          <div className="risk-card-subtitle">
            This Table lists the audit logs
          </div>
        </div>
        <div
          id="table-tool-bar"
          style={userAdminStyle.toolBarWrapper}
          className="mb-1"
        >
          <div id={"time-filter"} style={userAdminStyle.timeFilter}>
            <CustomDatePicker
              value={this.state.startingDate}
              CustomOnChange={(value) => {
                //console.log(JSDateTimeToDBDateTime(value));
                this.setState({ startingDate: JSDateTimeToDBDateTime(value) });
              }}
            />
            <div style={userAdminStyle.dateSeparatorFont}>to</div>
            <CustomDatePicker
              value={this.state.endingDate}
              CustomOnChange={(value) => {
                //console.log(JSDateTimeToDBDateTime(value));
                this.setState({ endingDate: JSDateTimeToDBDateTime(value) });
              }}
            />
            <div className={"div_hover"} style={userAdminStyle.iconWrapper}>
              <IconSVG
                wrapperStyle={{ width: "100%", height: "100%" }}
                name={"search"}
                color={"white"}
                onClick={() => {
                  this.props.handleLogRequest({
                    start_time: this.state.startingDate,
                    end_time: this.state.endingDate,
                    //username: JSON.parse(sessionStorage.getItem("MyProfile")).username,
                  });
                }}
                size={1}
              />
            </div>
          </div>
          <div className="mx-1">
            <SearchBar
              searchCallBack={this.onSearchCallBack}
              hint={"Search by user name..."}
            />
          </div>
        </div>

        <Grid
          style={userAdminStyle.gridStyle}
          {...this.state.dataState}
          data={this.customOrderBy(displayResult, this.state.sort)}
          sortable
          onDataStateChange={this.dataStateChange}
          onRowClick={this.rowClick}
          sort={this.state.sort}
          rowRender={this.rowRender}
          onSortChange={(e) => {
            var sortConfig = e.sort[0];
            if (!sortConfig) {
              this.setState({
                sort: [{ data: this.props.data }],
              });
            } else if (sortConfig.field === "formatDateTime") {
              sortConfig = { ...sortConfig, data: this.props.data };
              this.setState({
                sort: [sortConfig],
              });
            }
          }}
          customizedProps={"123"}
        >
          <Column
            field="log_name"
            title="Log Name"
            width={(200 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="log_message"
            title="Log Message"
            headerCell={HeaderRender}
          />
          <Column
            field="log_level"
            title="Log Type"
            width={(100 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="username"
            title="User Name"
            width={(100 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="ou_name"
            title="Entity"
            width={(100 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="tenant"
            title="Domain"
            width={(100 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="log_time"
            title="Log Time"
            width={(250 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
        </Grid>
      </div>
    );
  }

  // search related
  onSearchCallBack = (e) => {
    let searchContent = e.target.value;

    let resultData = this.state.filterData.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    let searchData = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );

    if (!searchContent) {
      this.setState({ result: resultData, searchData: searchData });
      return;
    }
    // find the user that suits the search keywords (by display name)
    let userOnSearch = this.state.filterData.filter((userItem) => {
      let matchDisplayName = false;
      if (userItem.display_name) {
        matchDisplayName = userItem.display_name
          .toLowerCase()
          .includes(searchContent.toLowerCase());
      }
      return matchDisplayName;
    });
    this.setState({ searchData: userOnSearch, result: userOnSearch });
  };

  // filter related
  createDataState = (dataState) => {
    let resultData = this.state.searchData.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    let filterData = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    return {
      result: process(resultData, dataState).data,
      filterData: process(filterData, dataState).data,
      dataState: dataState,
    };
  };

  dataStateChange = (event) => {
    let dataState = event.data;
    dataState.take = 100;
    this.setState(this.createDataState(dataState));
  };

  // sorting function
  customOrderBy = (data, sort) => {
    let result = [];

    let sortedData = orderBy(data, sort);

    while (sortedData.length !== 0) {
      //console.log(sortedData);
      if (sortedData[0].isParentRisk) {
        let parentID = sortedData[0].sourceRiskID;
        result = result.concat(sortedData[0]);
        sortedData = sortedData.filter((risk) => {
          return risk.sourceRiskID !== parentID;
        });
        let subRisk = sortedData.filter((risk) => {
          return risk.parentRisk_id === parentID;
        });
        result = result.concat(subRisk);
        sortedData = sortedData.filter((risk) => {
          return risk.parentRisk_id !== parentID;
        });
      } else {
        let parentID = sortedData[0].parentRisk_id;
        let parentRiskSort = sortedData.filter((risk) => {
          return risk.sourceRiskID === parentID;
        });
        result = result.concat(parentRiskSort);
        sortedData = sortedData.filter((risk) => {
          return risk.sourceRiskID !== parentID;
        });
        let subRisk = sortedData.filter((risk) => {
          return risk.parentRisk_id === parentID;
        });
        result = result.concat(subRisk);
        sortedData = sortedData.filter((risk) => {
          return risk.parentRisk_id !== parentID;
        });
      }
    }
    return result;
  };
  rowRender(trElement, props) {
    const parentRow = { backgroundColor: "#00000066" };
    const subRiskRow = { backgroundColor: "#00000000" };
    const trProps = {
      style: props.dataItem.isParentRisk ? parentRow : subRiskRow,
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  }
}

export { LogsTable };
