import React from "react";
// this part i think can use namecard from styleguide
import {
  mapping,
  ButtonSolid,
  NameCard,
  NonValidationInput,
  DropDownListButton,
  CustomizedCheckBox,
  userAdminStyle,
} from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

const passwordRegex = new RegExp(
  "^(^(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$)?(^(?=.*d)(?=.*[a-z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*d)(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?(^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$)?$"
);
const passwordValidator = (value) =>
  !value
    ? "Field can not be empty"
    : passwordRegex.test(value)
    ? "valid"
    : "Please enter a valid password.";

class UserAccountDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      password: "",
      display_name: "",
      title_id: this.props.titleList.map((a) => a.title_id)[0],
      designation: this.props.titleList.map((a) => a.display_name)[0],
      is_sso: false,
      access_level: 3,
      is_active: true,
      is_pwd_change_required: true,
    };
  }

  render() {
    //console.log(this.props.accessLevel[2]);
    // console.log(this.props.user);
    return (
      <div name={"NameCard"} style={{ width: "460px", margin: "auto" }}>
        <div style={userAdminStyle.accountDetailSearchDirectory}>
          <div style={userAdminStyle.userInfoStyle}>User Account Detail</div>
          <div style={userAdminStyle.userDetailsStyle}>
            <NameCard
              initial={this.state.display_name
                .split(" ")
                .map((a) => {
                  if (a[0]) {
                    return a[0].toUpperCase();
                  } else {
                    return "";
                  }
                })
                .join("")}
              name={this.state.display_name}
              title={this.state.designation}
              roleStyle={{ width: "10rem" }}
            />
          </div>
          <div style={userAdminStyle.userPhoneStyle}>{this.state.phone}</div>
          <div style={userAdminStyle.userPhoneStyle}>
            {this.state.department}
          </div>
          <div style={userAdminStyle.fieldPadding} />
          <NonValidationInput
            title={"Username"}
            defaultValue={this.state.username}
            width={"100%"}
            onChange={(event) => {
              this.setState({ username: event.value.replace(/^\s+|\s+$/, "") });
            }}
          />
          <div style={userAdminStyle.fieldPadding} />
          <NonValidationInput
            title={"Email"}
            defaultValue={this.state.email}
            width={"100%"}
            onChange={(event) => {
              this.setState({ email: event.value });
            }}
          />
          <div style={userAdminStyle.fieldPadding} />
          <NonValidationInput
            title={"Password"}
            type={"password"}
            onChange={(event) => {
              this.setState({ password: event.value });
            }}
            fieldstyle={
              passwordValidator(this.state.password) === "valid"
                ? userAdminStyle.ValidFieldStyle
                : userAdminStyle.InvalidFieldStyle
            }
          />
          {passwordValidator(this.state.password) !== "valid" && (
            <div
              style={{
                ...mapping["paragraph/small/lightleft"],
                position: "absolute",
                paddingTop: "2px",
                right: "15px",
                color: mapping["Color_Basic_Danger"],
              }}
            >
              {passwordValidator(this.state.password)}
            </div>
          )}
          <div style={userAdminStyle.fieldPadding} />
          <NonValidationInput
            title={"Display Name"}
            defaultValue={this.state.display_name}
            width={"100%"}
            onChange={(event) => {
              let name = event.value
                .replace(/\s+/g, " ") // merge in between space
                .replace(/^\s+|\s+$/, ""); // trim front and end space
              this.setState({ display_name: name });
            }}
          />
          <div style={userAdminStyle.fieldPadding} />
          <DropDownListButton
            title={"Designation"}
            data={this.props.titleList.map((a) => a.display_name)}
            value={this.props.titleList[this.state.title_id]}
            DropDownButtonStyle={userAdminStyle.titleDropDownButtonStyle}
            CustomOnChange={this.handleTitleChange}
          />
          <div style={userAdminStyle.fieldPadding} />
          <DropDownListButton
            title={"Access Level"}
            DropDownButtonStyle={userAdminStyle.titleDropDownButtonStyle}
            data={Object.values(this.getAccessLevelHigherThanCurrentUser())}
            //data={Object.values(this.props.accessLevel)}
            value={this.props.accessLevel[this.state.access_level]}
            CustomOnChange={this.handleAccessLevelChange}
          />
          <div style={userAdminStyle.fieldPadding} />
          <div style={userAdminStyle.formCheckBoxStyle}>
            <div style={userAdminStyle.checkBoxTitleStyle}>SSO login</div>
            <CustomizedCheckBox
              value={this.state.is_sso}
              onCheckChange={() => {
                this.setState({ is_sso: !this.state.is_sso });
              }}
            />
          </div>
          <div style={userAdminStyle.formCheckBoxStyle}>
            <div style={userAdminStyle.checkBoxTitleStyle}>Active</div>
            <CustomizedCheckBox
              value={this.state.is_active}
              onCheckChange={() => {
                this.setState({ is_active: !this.state.is_active });
              }}
            />
          </div>
          <div style={userAdminStyle.formCheckBoxStyle}>
            <div style={userAdminStyle.checkBoxTitleStyle}>
              Password Change Required
            </div>
            <CustomizedCheckBox
              value={this.state.is_pwd_change_required}
              onCheckChange={() => {
                this.setState({
                  is_pwd_change_required: !this.state.is_pwd_change_required,
                });
              }}
            />
          </div>
        </div>
        <div style={userAdminStyle.accountDetailButtonPosition}>
          <ButtonSolid
            width={userAdminStyle.cancelButtonWidth}
            height={userAdminStyle.buttonHeight}
            name={"Cancel"}
            color={"Secondary"}
            clickEvent={() => {
              navigateToUrl("/admin");
            }}
          />
          <div style={userAdminStyle.rightButtonPosition}>
            <ButtonSolid
              width={userAdminStyle.cancelButtonWidth}
              height={userAdminStyle.buttonHeight}
              name={"Next"}
              disabled={passwordValidator(this.state.password) !== "valid"}
              clickEvent={() => {
                this.props.saveNewUserInfo(this.state);
                this.props.handleNext();
              }}
            />
            <div style={{ width: "1rem" }} />
            <ButtonSolid
              width={userAdminStyle.cancelButtonWidth}
              height={userAdminStyle.buttonHeight}
              name={"Back"}
              clickEvent={() => {
                navigateToUrl("/admin/manageUser");
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  getAccessLevelHigherThanCurrentUser = () => {
    let filtered = Object.keys(this.props.accessLevel)
      .filter((lvl) => {
        return lvl > this.props.currentUser.access_level;
      })
      .reduce((obj, key) => {
        obj[key] = this.props.accessLevel[key];
        return obj;
      }, {});
    return filtered;
  };

  handleAccessLevelChange = (event) => {
    let access_level = Object.keys(this.props.accessLevel).find(
      (key) => this.props.accessLevel[key] === event.value
    );
    this.setState({ access_level: access_level });
  };

  handleTitleChange = (event) => {
    //console.log(this.props.titleList);
    //console.log(event.value);
    let title_id = this.props.titleList.filter((title) => {
      return title.display_name === event.value;
    })[0].title_id;
    this.setState({ title_id: title_id, designation: event.value });
  };
}
export { UserAccountDetail };
