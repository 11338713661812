import React from "react";
import {
  mapping,
  NonValidationInput,
  ButtonSolid,
  userAdminStyle,
} from "@aim-mf/styleguide";

// this part i think can use AutoComplete from styleguide

class TitleEditPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title_name: "",
    };
  }

  render() {
    //console.log(this.props.data);
    return (
      <div style={userAdminStyle.RiskCreationFormWrapStyle}>
        <div style={userAdminStyle.RiskCreationFormStyle}>
          <div style={userAdminStyle.formBgStyle}>
            <div style={mapping["heading/h5/lightleft"]}>Edit Title</div>
            <div style={{ height: "3.125rem" }} />
            <div style={mapping["forms/label/1-default"]}>Title name</div>
            <NonValidationInput
              defaultValue={this.props.data.display_name}
              fieldstyle={userAdminStyle.InputFieldStyle}
              width={"100%"}
              onChange={(event) => {
                // eslint-disable-next-line no-console
                this.setState({ title_name: event.value });
              }}
            />
            <div style={{ height: "2.5rem" }} />
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ButtonSolid
                name={"cancel"}
                color={"Secondary"}
                width={userAdminStyle.cancelButtonStyle}
                height={userAdminStyle.buttonHeight}
                clickEvent={this.props.cancelFunction}
              />
              <ButtonSolid
                name={"Submit Change"}
                color={"Primary"}
                width={userAdminStyle.submitButtonStyle}
                height={userAdminStyle.buttonHeight}
                clickEvent={() => {
                  this.props.submitFunction({
                    display_name: this.state.title_name,
                    title_id: this.props.data.title_id,
                  });
                  this.props.cancelFunction();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { TitleEditPopUp };
